import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LogoBlue from 'images/LivingSpace-Wordmark.svg';
import { RichText } from 'prismic-reactjs';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  logo: {
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      width: '200px',
    },
  },
  leftDivider: {
    width: '2px',
    backgroundColor: '#58696a',
    height: 'auto',
  },
});

function TitleCaptionText(props) {
  const { classes, data, logo, titlecomponent, xs, md, lg } = props;
  const xsVal = xs ? xs : 12;
  const mdVal = md ? md : 6;
  const lgVal = lg ? lg : 6;
  return (
    <Grid container justify="flex-start">
      <Grid item xs={xsVal} md={mdVal} lg={lgVal}>
        <Box display="flex" flexDirection="row" justifyContent="left">
          <Box
            className={classes.leftDivider}
            mr={10}
            display={{ xs: 'none', md: 'block' }}
          />
          <Box display="flex" flexDirection="column">
            {data.title && data.title[0] && (
              <Typography
                component={`${titlecomponent ? titlecomponent : 'h2'}`}
                variant={`${titlecomponent ? titlecomponent : 'h2'}`}
                color="textPrimary"
                gutterBottom
                align="left"
              >
                {data.title[0].text}
              </Typography>
            )}
            {data.caption && data.caption[0] && (
              <Box pt={2} pb={4}>
                <Typography
                  component="p"
                  variant="h5"
                  color="textPrimary"
                  align="left"
                >
                  {data.caption[0].text}
                </Typography>
              </Box>
            )}
            {data.text && (
              <Typography component="span" variant="body1" align="left">
                <RichText render={data.text} />
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

TitleCaptionText.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  logo: PropTypes.bool,
  titlecomponent: PropTypes.string,
  xs: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

export default withStyles(styles)(TitleCaptionText);
