import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import BackgroundImage from 'gatsby-background-image';

import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  wrapper: {
    height: 'calc(100vh - 6vw)',
    [theme.breakpoints.up('lg')]: {
      height: '590px',
    },
  },
  container: {
    height: '100% !important',
  },
  halfbox: {
    height: '100%',
    width: '100%',
    marginBottom: '16px',
  },
  fullbox: {
    height: '100%',
    width: '100%',
  },
  marginBtn: {
    marginBottom: '16px',
  },
  prMobile: {
    [theme.breakpoints.down('md')]: {
      paddingRight: '8px',
    },
  },
  plMobile: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '8px',
    },
  },
});

function ImageGrid(props) {
  const { data, classes } = props;
  const doc = data.slice(0, 1).pop();
  if (!doc) return null;
  return (
    <Box className={classes.wrapper}>
      <div className={`${classes.container} spaced-grid-wrapper`}>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={3}>
            <Grid container className={classes.container}>
              {doc.tile1_imageSharp && (
                <Grid item xs={12} className={classes.marginBtn}>
                  <BackgroundImage
                    Tag="div"
                    className={classes.halfbox}
                    fluid={doc.tile1_imageSharp.childImageSharp.fluid}
                  />
                </Grid>
              )}
              {doc.tile2_imageSharp && (
                <Grid item xs={12}>
                  <BackgroundImage
                    Tag="div"
                    className={classes.halfbox}
                    fluid={doc.tile2_imageSharp.childImageSharp.fluid}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container className={classes.container}>
              {doc.tile3_imageSharp && (
                <Grid item xs={12} className={classes.marginBtn}>
                  <BackgroundImage
                    Tag="div"
                    className={classes.halfbox}
                    fluid={doc.tile3_imageSharp.childImageSharp.fluid}
                  />
                </Grid>
              )}
              {doc.tile4_imageSharp && (
                <Grid item xs={12}>
                  <BackgroundImage
                    Tag="div"
                    className={classes.halfbox}
                    fluid={doc.tile4_imageSharp.childImageSharp.fluid}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Box component={Grid} order={{ xs: 2, md: 1 }} item xs={12} md={3}>
            <Grid container className={classes.container}>
              {doc.tile5_imageSharp && (
                <Box
                  component={Grid}
                  item
                  xs={12}
                  md={12}
                  mb={2}
                  pr={0}
                  className={classes.marginBtn}
                >
                  <BackgroundImage
                    Tag="div"
                    className={classes.halfbox}
                    fluid={doc.tile5_imageSharp.childImageSharp.fluid}
                  />
                </Box>
              )}
              {doc.tile6_imageSharp && (
                <Box component={Grid} item xs={12} md={12} pl={0}>
                  <BackgroundImage
                    Tag="div"
                    className={classes.halfbox}
                    fluid={doc.tile6_imageSharp.childImageSharp.fluid}
                  />
                </Box>
              )}
            </Grid>
          </Box>
          {doc.tile7_imageSharp && (
            <Box component={Grid} order={{ xs: 1, md: 2 }} item xs={6} md={3}>
              <BackgroundImage
                Tag="div"
                className={classes.fullbox}
                fluid={doc.tile7_imageSharp.childImageSharp.fluid}
              />
            </Box>
          )}
        </Grid>
      </div>
    </Box>
  );
}

ImageGrid.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageGrid);
