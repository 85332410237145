import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';
import { withStyles } from '@material-ui/styles';
import { ExternalButtonBlockDark } from 'components/ui';

const styles = theme => ({
  body: {
    fontSize: '1rem',
    letterSpacing: '0.75px',
  },
  divider: {
    height: '1px',
    background: theme.palette.background.border,
    width: '80%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
});

function Careers(props) {
  const { data, fields, classes } = props;
  return (
    <React.Fragment>
      {fields.length > 0 && (
        <React.Fragment>
          <Container maxWidth={false}>
            <Box mb={10}>
              <Typography component="h2" variant="h3" align="center">
                {data.title[0].text}
              </Typography>
            </Box>
          </Container>
          <Container maxWidth="lg">
            {fields.map((item, key, arr) => {
              var paddingBtm;
              if (arr.length - 1 === key) {
                paddingBtm = 0;
              } else {
                paddingBtm = 10;
              }
              return (
                <Box key={key} pb={paddingBtm}>
                  <Typography
                    component="h4"
                    variant="h6"
                    align="center"
                    gutterBottom
                  >
                    {item.job_title[0].text}
                  </Typography>
                  <Typography component="h4" variant="subtitle1" align="center">
                    {item.department_and_location[0].text}
                  </Typography>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className={classes.body}
                    align="center"
                  >
                    <RichText render={item.job_description} />
                  </Typography>
                  <Box pt={3} display="flex" justifyContent="center">
                    <ExternalButtonBlockDark
                      link={item.link.url}
                      text="Apply Now"
                    />
                  </Box>
                  {arr.length - 1 !== key && (
                    <Box className={classes.divider} mx="auto" mt={10} />
                  )}
                </Box>
              );
            })}
          </Container>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

Careers.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Careers);
