import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

function InlineImageGrid(props) {
  const { primary, data } = props;
  const logoMargin = data.length > 4 ? 0 : 4;
  return (
    <Container maxWidth={`${data.length > 4 ? 'xl' : 'lg'}`}>
      {primary.title && (
        <Typography component="h4" variant="h4" align="center">
          {primary.title[0].text}
        </Typography>
      )}
      <Box component={Grid} container justifyContent="center" pt={2}>
        {data.map((item, key) => (
          <Box key={key} component={Grid} item xs={4} md={3} lg={2} mx={logoMargin}>
            <Img
              fluid={item.imageSharp.childImageSharp.fluid}
              style={{ minWidth: '150px', maxWidth: '350px' }}
            />
          </Box>
        ))}
      </Box>
    </Container>
  );
}

InlineImageGrid.propTypes = {
  primary: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default InlineImageGrid;
