import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const K_WIDTH = 40;
const K_HEIGHT = 40;

const styles = theme => ({
  marker: {
    position: 'relative',
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    color: theme.palette.text.white,
    fontSize: 16,
    fontWeight: 'bold',
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrow: {
    position: 'absolute',
    zIndex: -1,
    width: '0px',
    height: '0px',
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: `20px solid ${theme.palette.primary.main}`,
    bottom: '-6px',
  },
});

function LocationMap(props) {
  const { data, classes } = props;
  const defaultProps = {
    center: {
      lat: data.latitude,
      lng: data.longitude,
    },
    zoom: 12,
  };
  return (
    <div style={{ height: '500px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GOOGLE_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        options={{
          styles: [
            {
              elementType: 'geometry',
              stylers: [
                {
                  color: '#f5f5f5',
                },
              ],
            },
            {
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#e1e6ea',
                },
              ],
            },
            {
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#000000',
                },
              ],
            },
            {
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              elementType: 'labels.text',
              stylers: [
                {
                  color: '#f40606',
                },
              ],
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#1f3242',
                },
              ],
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#f5f5f5',
                },
              ],
            },
          ],
        }}
      >
        <div
          className={classes.marker}
          lat={data.latitude}
          lng={data.longitude}
        >
          L
          <div className={classes.arrow} />
        </div>
      </GoogleMapReact>
    </div>
  );
}

LocationMap.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LocationMap);
