import React from 'react';
import PropTypes from 'prop-types';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  slider: {
    '&.awssld': {
      ' --loader-bar-color': '#1f3242',
    },
    '& .awssld__controls button span': {
      '&:before, &:after': {
        backgroundColor: theme.palette.text.white,
      },
    },
    '& .awssld__bullets': {
      bottom: '40px',
      zIndex: 2,
      '& button': {
        backgroundColor: theme.palette.text.white,
        width: '10px',
        height: '10px',
        [theme.breakpoints.up('lg')]: {
          width: '16px',
          height: '16px',
        },
      },
    },
    '& .awssld__content': {
      alignItems: 'normal',
    },
  },
  textWrapper: {
    zIndex: '10',
    position: 'relative',
    height: '100%',
    width: '80%',
    margin: '0 auto',
  },
  ident: {
    width: '20px',
    marginTop: '10px',
    [theme.breakpoints.up('md')]: {
      width: '50px',
      marginTop: '40px',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      letterSpacing: 'normal',
    },
  },
});

function ImageSlider(props) {
  const { data, classes } = props;
  const getSlides = () => {
    return data.map((item, key) => (
      <div key={key} data-src={item.image.url}>
        {item.title && item.title[0].text && (
          <Box
            className={classes.textWrapper}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box width="50">
              <Typography
                component="p"
                variant="h2"
                color="secondary"
                align="center"
                className={classes.title}
              >
                {item.title[0].text}
              </Typography>
            </Box>
          </Box>
        )}
      </div>
    ));
  };
  return (
    <AwesomeSlider className={classes.slider}>{getSlides()}</AwesomeSlider>
  );
}

ImageSlider.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageSlider);
