import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

function Image(props) {
  const { data } = props;
  return (
    <Box
      component="img"
      width="100%"
      src={`${data.image.url}&q=45&lossless=1`}
      alt={data.image.alt}
    />
  );
}

Image.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Image;
