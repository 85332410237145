import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import BackgroundImage from 'gatsby-background-image';
import { withStyles } from '@material-ui/styles';
import { ButtonBlockDark } from 'components/ui';
import linkResolver from 'utils/linkResolver';

const styles = theme => ({
  bgImageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
    minHeight: '150px',
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      height: '385px',
    },
  },
});

function WidescreenImageBlock(props) {
  const { data, fields, classes } = props;
  return (
    <Container maxWidth={false}>
      <BackgroundImage
        className={classes.bgImageWrapper}
        Tag="section"
        fluid={data.imageSharp.childImageSharp.fluid}
      >
        {data.title && (
          <Box>
            <Typography component="p" variant="h4" color="secondary">
              {data.title[0].text}
            </Typography>
          </Box>
        )}
        {fields && (
          <Box pt={data.title ? '6' : 0} display="flex" flexDirection="row">
            {fields.map((item, key) => (
              <React.Fragment key={key}>
                {item.link_title && item.link && (
                  <Box px={4}>
                    <ButtonBlockDark
                      text={item.link_title[0].text}
                      link={
                        item.link._meta ? linkResolver(item.link._meta) : ''
                      }
                    />
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Box>
        )}
      </BackgroundImage>
    </Container>
  );
}

WidescreenImageBlock.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
};

export default withStyles(styles)(WidescreenImageBlock);
