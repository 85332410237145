import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Logo from 'images/LivingSpaceLogoWhite.svg';
import { ButtonOutlineLight, ButtonOutlineLightExternal } from 'components/ui';
import BackgroundImage from 'gatsby-background-image';
import linkResolver from 'utils/linkResolver';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const styles = theme => ({
  uppercase: {
    textTransform: 'uppercase',
  },
  bgImageWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '400px',
    padding: '40px 0px',
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100vh - 6vw)',
    },
  },
  indent: {
    width: '30px',
    [theme.breakpoints.up('lg')]: {
      width: '50px',
    },
  },
  logo: {
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      width: '200px',
    },
  },
  socialicon: {
    color: theme.palette.text.white,
    fontSize: '2.5rem',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.text.light,
    },
  },
});

function LargeImageBlock(props) {
  const { data, classes } = props;
  return (
    <Container maxWidth={false}>
      <BackgroundImage
        className={classes.bgImageWrapper}
        Tag="section"
        fluid={data.imageSharp.childImageSharp.fluid}
      >
        <Box />

        <Box px={{ xs: 10, lg: 30 }}>
          {data.title && (
            <Typography
              component="p"
              variant="h2"
              align="center"
              color="secondary"
              className={classes.uppercase}
            >
              {data.title[0].text}
            </Typography>
          )}
        </Box>
        <Box>
          {data.link && (
            <React.Fragment>
              {data.link_title &&
                data.link.__typename != 'PRISMIC__ExternalLink' && (
                  <ButtonOutlineLight
                    text={data.link_title[0].text}
                    link={`${data.link ? linkResolver(data.link._meta) : '/'}`}
                  />
                )}
              {data.link_title &&
                data.link.__typename == 'PRISMIC__ExternalLink' && (
                  <ButtonOutlineLightExternal
                    text={data.link_title[0].text}
                    link={`${data.link.url ? data.link.url : '/'}`}
                  />
                )}
            </React.Fragment>
          )}
        </Box>
      </BackgroundImage>
    </Container>
  );
}

LargeImageBlock.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(LargeImageBlock);
