import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { RichText } from 'prismic-reactjs';
import { ButtonBlockDark } from 'components/ui';
import linkResolver from 'utils/linkResolver';

const styles = {
  image: {
    maxWidth: '100%',
  },
  body: {
    fontSize: '1rem',
    letterSpacing: '0.75px',
  },
};

function V2TextLeftImageRight(props) {
  const { data, classes } = props;
  return (
    <Container maxWidth={false}>
      <Grid container alignItems="center">
        <Box component={Grid} item xs={12} md={6} mb={[4, 4, 0]}>
          <Box pr={[0, 2, 2, 16]}>
            <Grid container justify="center">
              <Grid item>
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Typography
                    component="h4"
                    variant="h4"
                    color="textPrimary"
                    gutterBottom
                  >
                    {data.title[0].text}
                  </Typography>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className={classes.body}
                  >
                    <RichText render={data.text} />
                  </Typography>
                  {data.link_title && data.link && (
                    <Box>
                      <ButtonBlockDark
                        text={data.link_title[0].text}
                        link={
                          data.link._meta ? linkResolver(data.link._meta) : ''
                        }
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Grid item xs={12} md={6}>
          <Img
            className={classes.image}
            fluid={data.imageSharp.childImageSharp.fluid}
            alt={data.image.alt}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

V2TextLeftImageRight.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(V2TextLeftImageRight);
