import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import Logo from 'images/LivingSpaceLogoWhite.svg';
import linkResolver from 'utils/linkResolver';

const styles = theme => ({
  slider: {
    '&.awssld': {
      '--slider-height-percentage': '40%',
      '--slider-transition-duration': '700ms',
      '--organic-arrow-thickness': '4px;',
      ' --organic-arrow-border-radius': '0px',
      ' --organic-arrow-height': '25px',
      ' --organic-arrow-color': '#ffffff',
      ' --control-button-width': '5%',
      ' --control-button-height': '25%',
      ' --control-button-background': 'transparent',
      ' --control-bullet-color': '#62a4fa',
      ' --control-bullet-active-color': '#538bd5',
      ' --loader-bar-color': '#1f3242',
      ' --loader-bar-height': '6px',
      [theme.breakpoints.up('md')]: {
        '--slider-height-percentage': '25%',
      },
      '& .awssld__content': {
        '& img': {
          '-webkit-transform': 'scale(1.02)',
          transform: 'scale(1.02)',
          '-webkit-transform-origin': 'bottom',
          'transform-origin': 'bottom',
          '-webkit-transition': '-webkit-transform .5s',
          transition: 'transform .5s,-webkit-transform .5s',
        },
        '&:hover': {
          '& img': {
            transform: 'scale(1)',
          },
          '& > div img': {
            transform: 'scale(1.02)',
          },
        },
        '& > div': {
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
    '& .awssld__controls button span': {
      '&:before, &:after': {
        backgroundColor: theme.palette.text.white,
      },
    },
    '& .awssld__bullets': {
      bottom: '20px',
      zIndex: 2,
      '& button': {
        backgroundColor: theme.palette.text.white,
      },
    },
  },
  textWrapper: {
    zIndex: '10',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  icon: {
    width: '50px',
    [theme.breakpoints.up('sm')]: {
      width: '100px',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      letterSpacing: 'normal',
    },
  },
  logo: {
    width: '100px',
  },
  brandLogo: {
    marginBottom: '20px',
    width: '80px',
    [theme.breakpoints.up('lg')]: {
      width: '100px',
    },
  },
  link: {
    width: '100%',
    height: '100%',
    display: 'block',
    textDecoration: 'none',
  },
});

function SchemeDetails(props) {
  const { data, classes } = props;
  const getSlides = () => {
    return data.map((item, key) => (
      <div key={key} data-src={item.image['1062x248'].url}>
        <Link
          to={item.link ? linkResolver(item.link._meta) : ''}
          className={classes.link}
        >
          {item.title && (
            <div className={classes.textWrapper}>
              <Box>
                <Logo className={classes.brandLogo} />
              </Box>
              <Box>
                <Img
                  fluid={item.logo_imageSharp.childImageSharp.fluid}
                  className={classes.logo}
                />
              </Box>
              <Box>
                <Typography
                  component="p"
                  variant="subtitle1"
                  color="secondary"
                  align="center"
                  className={classes.title}
                >
                  {item.title[0].text}
                </Typography>
              </Box>
            </div>
          )}
        </Link>
      </div>
    ));
  };
  return (
    <AwesomeSlider className={classes.slider} bullets={false}>
      {getSlides()}
    </AwesomeSlider>
  );
}

SchemeDetails.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SchemeDetails);
