import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LogoBlue from 'images/LivingSpace-Wordmark.svg';
import { RichText } from 'prismic-reactjs';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  logo: {
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      width: '200px',
    },
  },
});

function TitleCaptionTwoColumnText(props) {
  const { classes, data, logo, titlecomponent, xs, md, lg } = props;
  const xsVal = xs ? xs : 12;
  const mdVal = md ? md : 6;
  const lgVal = lg ? lg : 5;
  return (
    <Box>
      <Grid container justify="center">
        <Grid item xs={xsVal} md={mdVal} lg={lgVal}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            {data.title && data.title[0] && (
              <Typography
                component={`${titlecomponent ? titlecomponent : 'h2'}`}
                variant={`${titlecomponent ? titlecomponent : 'h2'}`}
                color="textPrimary"
                gutterBottom
                align="center"
              >
                {data.title[0].text}
              </Typography>
            )}
            {data.caption && data.caption[0] && (
              <Box pt={2} pb={4}>
                <Typography
                  component="p"
                  variant="subtitle1"
                  color="textPrimary"
                >
                  {data.caption[0].text}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <div className="spaced-grid-wrapper">
            <Grid container justify="space-between">
              <Box component={Grid} item xs={12} md mb={{ xs: 4, md: 0 }}>
                {data.text_left_column && (
                  <Typography component="p" variant="body1" align="left">
                    {RichText.asText(data.text_left_column)}
                  </Typography>
                )}
              </Box>
              <Grid item xs={1} />
              <Grid item xs={12} md>
                {data.text_right_column && (
                  <Typography component="p" variant="body1" align="left">
                    {RichText.asText(data.text_right_column)}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

TitleCaptionTwoColumnText.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  logo: PropTypes.bool,
  titlecomponent: PropTypes.string,
  xs: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

export default withStyles(styles)(TitleCaptionTwoColumnText);
