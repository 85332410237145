import React from 'react';
import PropTypes from 'prop-types';
import linkResolver from 'utils/linkResolver';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Spacer from 'components/spacer';
import { ButtonUnderline } from 'components/ui';
import { withStyles } from '@material-ui/styles';

const styles = {
  image: {
    maxWidth: '100%',
  },
};

function ThreeColumns(props) {
  const { data, fields, classes } = props;
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component="h2" variant="h2" align="center" gutterBottom>
          {data.title[0].text}
        </Typography>
        <Typography component="p" variant="h5" align="center">
          {data.caption[0].text}
        </Typography>
      </Box>
      <Box pt={10}>
        <Grid container spacing={4}>
          {fields.map(({ image, title, caption, link }, key) => (
            <Grid key={key} item xs={12} md={4}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={6} md={12}>
                  <Box mb={[0, 4]}>
                    <img src={`${image.url}&fit=crop&w=985&h=656`} alt={image.alt} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <Typography
                    component="h6"
                    variant="h5"
                    align="center"
                    gutterBottom
                  >
                    {title[0].text}
                  </Typography>
                  <Typography component="p" variant="h6" align="center">
                    {caption[0].text}
                  </Typography>
                  <Box display="flex" justifyContent="center" mt={4}>
                    <ButtonUnderline
                      link={link ? linkResolver(link._meta) : ''}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

ThreeColumns.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ThreeColumns);
