import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import clsx from 'clsx';
import linkResolver from 'utils/linkResolver';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Logo from 'images/LivingSpaceLogoWhite.svg';
import { withStyles } from '@material-ui/styles';

import Img from 'gatsby-image';
import styled from 'styled-components';

const FakeBgImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
  z-index: -1;
  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;';
  }
`;

const styles = theme => ({
  back: {
    background: 'transparent',
    border: 0,
    padding: 0,
    position: 'absolute',
    top: '50%',
    left: '1vw',
    outline: 0,
  },
  next: {
    background: 'transparent',
    border: 0,
    padding: 0,
    position: 'absolute',
    top: '50%',
    right: '1vw',
    outline: 0,
  },
  icon: {
    color: theme.palette.text.white,
    fontSize: '4rem',
  },
  slider: {
    outline: 0,
  },
  marginAuto: {
    marginTop: 'auto',
    zIndex: 2,
  },
  viewBtn: {
    transform: 'translateY(-80px)',
  },
  bg: {
    '-webkit-transform': 'scale(1.02)',
    transform: 'scale(1.02)',
    '-webkit-transform-origin': 'bottom',
    'transform-origin': 'bottom',
    '-webkit-transition': '-webkit-transform .5s',
    transition: 'transform .5s,-webkit-transform .5s',
  },
  slideLink: {
    width: '100%',
    height: '100%',
    display: 'block',
    overflow: 'hidden',
    textDecoration: 'none',
    '&:hover': {
      '& .staticBtn': {
        boxShadow: `inset 0px 0px 0px 3px ${theme.palette.secondary.main}`,
      },
      '& .bg': {
        transform: 'scale(1)',
      },
    },
  },
  btn: {
    display: 'block',
    border: `1px solid ${theme.palette.text.white}`,
    color: theme.palette.text.white,
    padding: '15px 42px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.secondary.main}`,
    transition: 'box-shadow 0.2s ease-in-out',
  },
  contentWrapper: {
    height: '100%',
  },
  logo: {
    marginBottom: '20px',
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      width: '150px',
    },
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: 'white',
  },
});

const ButtonStatic = ({ text, classes }) => {
  return (
    <Typography
      component="div"
      variant="caption"
      className={`${classes.btn} staticBtn`}
    >
      {text}
    </Typography>
  );
};

ButtonStatic.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};

const getWidth = () =>
  (typeof window !== 'undefined' && window.innerWidth) ||
  (typeof window !== 'undefined' && document.documentElement.clientWidth) ||
  (typeof window !== 'undefined' && document.body.clientWidth);

function Carousel(props) {
  const { fields, classes } = props;
  let [width, setWidth] = useState(getWidth());
  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth());
    };
    // set resize listener
    typeof window !== 'undefined' &&
      window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      typeof window !== 'undefined' &&
        window.removeEventListener('resize', resizeListener);
    };
  }, []);
  const SlideTotal =
    typeof window !== 'undefined'
      ? width > 1200
        ? 3
        : width > 900
        ? 2
        : 1
      : 3;
  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={150}
      visibleSlides={SlideTotal ? SlideTotal : 3}
      totalSlides={fields.length}
      infinite={true}
    >
      <Slider className={classes.slider}>
        {fields.map((item, key) => {
          var linkPath = '';
          if (item.link && item.link.__typename == 'PRISMIC__ExternalLink') {
            linkPath = item.link.url;
          } else {
            linkPath = linkResolver(item.link._meta);
          }

          return (
            <Slide key={key} index={key} style={{ overflow: 'hidden' }}>
              <Link to={linkPath} className={classes.slideLink}>
                {item && item.imageSharp && (
                  <FakeBgImage
                    className={`${classes.bg} bg`}
                    fluid={item.imageSharp.childImageSharp.fluid}
                  />
                )}
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="center"
                  flexDirection="column"
                  className={classes.contentWrapper}
                >
                  <Box mb={4} px={10}>
                    <Typography
                      component="p"
                      variant="h3"
                      color="secondary"
                      align="left"
                    >
                      {item.title[0].text}
                    </Typography>
                  </Box>
                  <Box mb={2} px={10}>
                    <Box className={classes.divider} mb={4} />
                    {item.content && item.content[0] && item.content[0].text && (
                      <Typography
                        component="p"
                        variant="subtitle1"
                        color="secondary"
                        align="left"
                      >
                        {item.content[0].text}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Link>
            </Slide>
          );
        })}
      </Slider>
      <ButtonBack className={classes.back}>
        <ArrowBackIosIcon className={classes.icon} />
      </ButtonBack>
      <ButtonNext className={classes.next}>
        <ArrowForwardIosIcon className={classes.icon} />
      </ButtonNext>
    </CarouselProvider>
  );
}

Carousel.propTypes = {
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Carousel);
