import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Logo from 'images/LivingSpaceLogoWhite.svg';

import BackgroundImage from 'gatsby-background-image';

const styles = theme => ({
  uppercase: {
    textTransform: 'uppercase',
  },
  bgImageWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '400px',
    padding: '40px 0px',
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100vh - 6vw)',
    },
  },
  logo: {
    width: '80px',
    [theme.breakpoints.up('lg')]: {
      width: '100px',
    },
  },
});

function VideoBlock(props) {
  const { data, classes } = props;
  return (
    <Container maxWidth={false}>
      <BackgroundImage
        className={classes.bgImageWrapper}
        Tag="section"
        fluid={data.imageSharp.childImageSharp.fluid}
      >
        <Box>
          <Logo className={classes.logo} />
        </Box>
        <Box>
          <Box mb={4}>
            <Typography
              component="p"
              variant="subtitle1"
              align="center"
              color="secondary"
              className={classes.uppercase}
            >
              {data.title[0].text}
            </Typography>
          </Box>
          <Box>
            <Typography
              component="p"
              variant="h2"
              align="center"
              color="secondary"
              className={classes.uppercase}
            >
              {ReactHtmlParser(data.body1[0].text)}
            </Typography>
          </Box>
        </Box>
        <Box></Box>
      </BackgroundImage>
    </Container>
  );
}

VideoBlock.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoBlock);
