import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import clsx from 'clsx';
import linkResolver from 'utils/linkResolver';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Logo from 'images/LivingSpaceLogoWhite.svg';
import { withStyles } from '@material-ui/styles';

import Img from 'gatsby-image';
import styled from 'styled-components';

const FakeBgImage = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: ${props => `url(${props.image})`};
`;

const styles = theme => ({
  back: {
    background: 'transparent',
    border: 0,
    padding: 0,
    position: 'absolute',
    top: '50%',
    left: '1vw',
    outline: 0,
  },
  next: {
    background: 'transparent',
    border: 0,
    padding: 0,
    position: 'absolute',
    top: '50%',
    right: '1vw',
    outline: 0,
  },
  icon: {
    color: theme.palette.text.white,
    fontSize: '4rem',
  },
  slider: {
    outline: 0,
  },
  marginAuto: {
    marginTop: 'auto',
  },
  viewBtn: {
    transform: 'translateY(-80px)',
  },
  bg: {
    '-webkit-transform': 'scale(1.02)',
    transform: 'scale(1.02)',
    '-webkit-transform-origin': 'bottom',
    'transform-origin': 'bottom',
    '-webkit-transition': '-webkit-transform .5s',
    transition: 'transform .5s,-webkit-transform .5s',
  },
  slideLink: {
    width: '100%',
    height: '100%',
    display: 'block',
    overflow: 'hidden',
    textDecoration: 'none',
    '&:hover': {
      '& .staticBtn': {
        boxShadow: `inset 0px 0px 0px 3px ${theme.palette.secondary.main}`,
      },
      '& .bg': {
        transform: 'scale(1)',
      },
    },
  },
  btn: {
    display: 'block',
    border: `1px solid ${theme.palette.text.white}`,
    color: theme.palette.text.white,
    padding: '15px 42px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.secondary.main}`,
    transition: 'box-shadow 0.2s ease-in-out',
  },
  logo: {
    width: '80px',
    [theme.breakpoints.up('lg')]: {
      width: '100px',
    },
    '&.hidden': {
      visibility: 'hidden',
    },
  },
  contentWrapper: {
    height: '100%',
  },
});

const ButtonStatic = ({ text, classes }) => {
  return (
    <Typography
      component="div"
      variant="caption"
      className={`${classes.btn} staticBtn`}
    >
      {text}
    </Typography>
  );
};

ButtonStatic.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};

const getWidth = () =>
  (typeof window !== 'undefined' && window.innerWidth) ||
  (typeof window !== 'undefined' && document.documentElement.clientWidth) ||
  (typeof window !== 'undefined' && document.body.clientWidth);

function Projects(props) {
  const { data, fields, classes } = props;
  let [width, setWidth] = useState(getWidth());

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth());
    };
    // set resize listener
    typeof window !== 'undefined' &&
      window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      typeof window !== 'undefined' &&
        window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const SlideTotal =
    typeof window !== 'undefined'
      ? width > 1200
        ? 3
        : width > 900
        ? 2
        : 1
      : 3;

  const SlideHeightSingle =
    typeof window !== 'undefined'
      ? width > 1200
        ? 50
        : width > 900
        ? 80
        : 150
      : 150;

  return (
    <Box>
      {data.title && (
        <Box mb={10}>
          {data.title && (
            <Typography component="h2" variant="h2" align="center" gutterBottom>
              {data.title[0].text}
            </Typography>
          )}
          {data.caption && (
            <Typography component="p" variant="h5" align="center">
              {data.caption[0].text}
            </Typography>
          )}
        </Box>
      )}
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={fields.length == 1 ? SlideHeightSingle : 150}
        visibleSlides={fields.length > 1 ? (SlideTotal ? SlideTotal : 1) : 1}
        totalSlides={fields.length}
        infinite={false}
      >
        <Slider className={classes.slider}>
          {fields.map((item, key) => (
            <Slide key={key} index={key} style={{ overflow: 'hidden' }}>
              <Link
                to={item.project._meta ? linkResolver(item.project._meta) : ''}
                className={classes.slideLink}
              >
                <FakeBgImage
                  className={`${classes.bg} bg`}
                  image={item.project.thumbnail_image.url}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  className={classes.contentWrapper}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    className={classes.marginAuto}
                  >
                    <Box mb={2}>
                      <Typography component="p" variant="h3" color="secondary" align="center">
                        {item.project.title[0].text}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography component="p" variant="h5" color="secondary">
                        {item.project.address
                          ? item.project.address[0].town[0].text
                          : null}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={clsx(classes.marginAuto, classes.viewBtn)}>
                    <ButtonStatic text="View" classes={classes} />
                  </Box>
                </Box>
              </Link>
            </Slide>
          ))}
        </Slider>
        {fields.length > 1 && (
          <React.Fragment>
            <ButtonBack className={classes.back}>
              <ArrowBackIosIcon className={classes.icon} />
            </ButtonBack>
            <ButtonNext className={classes.next}>
              <ArrowForwardIosIcon className={classes.icon} />
            </ButtonNext>
          </React.Fragment>
        )}
      </CarouselProvider>
    </Box>
  );
}

Projects.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Projects);
