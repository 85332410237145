import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ButtonBlockDark } from 'components/ui';
import { withStyles } from '@material-ui/styles';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const styles = theme => ({
  listItem: {
    listStylePosition: 'inside',
  },
  link: {
    textDecoration: 'none',
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
});

function Downloads(props) {
  const { data, content, classes } = props;
  return (
    <div className="spaced-grid-wrapper">
      <Grid container spacing={10}>
        <Grid item xs={12} md={8} lg={6}>
          <Typography component="h4" variant="h4" gutterBottom>
            {content.title[0].text}
          </Typography>
          <Typography component="p" variant="subtitle1">
            {content.text[0].text}
          </Typography>
          <Box pt={4}>
            <Typography component="p" variant="subtitle1">
              View / Download
            </Typography>
            <Box component="ul" p={0}>
              {data.map((item, key) => (
                <Typography
                  key={key}
                  component="li"
                  variant="subtitle1"
                  className={classes.listItem}
                >
                  <a
                    href={`${item.download_item ? item.download_item.url : ''}`}
                    target="_blank"
                    rel="noreferrer nofollow"
                    className={classes.link}
                  >
                    {item.title[0].text}
                  </a>
                </Typography>
              ))}
            </Box>
            <Box mt={4}>
              <ButtonBlockDark
                text="MAKE AN ENQUIRY"
                link={`/${process.env.contact_path}`}
                icon={<ArrowForwardIosIcon />}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} lg={6}>
          <Img fluid={content.imageSharp.childImageSharp.fluid} />
        </Grid>
      </Grid>
    </div>
  );
}

Downloads.propTypes = {
  data: PropTypes.array.isRequired,
  content: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Downloads);
