import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import { withStyles } from '@material-ui/styles';
import WarningIcon from '@material-ui/icons/Warning';
import counties from 'helpers/counties';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  label: {
    color: theme.palette.text.label,
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
    fontSize: '0.8rem',
  },
  labelHidden: {
    display: 'none',
  },
  input: {
    borderRadius: 0,
    border: `1px solid ${theme.palette.background.border}`,
    height: '40px',
    outline: 0,
    width: '100%',
    padding: '10px',
    '&:focus': {
      border: `2px solid ${theme.palette.background.border}`,
    },
  },
  halfWidth: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  border: {
    borderRight: `1px solid ${theme.palette.background.border}`,
    [theme.breakpoints.down('md')]: {
      borderRight: '0px',
    },
  },
  errorIcon: {
    paddingRight: '10px',
    fontSize: '2rem',
    color: theme.palette.text.error,
  },
  checkbox: {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    zIndex: 2,
    height: '25px',
    width: '25px',
    '&:checked': {
      '& ~ .checkmark': {
        '&:after': {
          display: 'block',
        },
      },
    },
  },
  checkmark: {
    position: 'relative',
    top: 0,
    left: 0,
    height: '25px',
    width: '25px',
    border: `1px solid ${theme.palette.background.border}`,
    backgroundColor: theme.palette.background.default,
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'none',
      left: '9px',
      top: '4px',
      width: '7px',
      height: '12px',
      border: `solid ${theme.palette.background.border}`,
      borderWidth: '0 3px 3px 0',
      transform: 'rotate(45deg)',
    },
  },
  divider: {
    height: '2px',
    width: '100%',
    background: theme.palette.primary.main,
  },
  btn: {
    cursor: 'pointer',
    display: 'inline-block',
    border: `3px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    color: theme.palette.text.white,
    padding: '5px 20px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.text.white}`,
    transition: 'box-shadow 0.2s ease-in-out',
    [theme.breakpoints.up('md')]: {
      padding: '15px 42px',
    },
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.text.white}`,
    },
  },
  submitting: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 10,
    backgroundColor: 'white',
    opacity: 0.9,
  },
});

const ContactMethods = ['Email', 'Phone', 'Post'];

function ContactForm(props) {
  const { data, fields, classes } = props;

  const { register, errors, handleSubmit } = useForm({
    mode: 'onChange',
  });

  // Server state handling
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    complete: false,
  });
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
      complete: true,
    });
  };

  const onSubmit = data => {
    setServerState({ submitting: true });
    let formData = new FormData();
    formData.append('Name', data.fullName);
    formData.append('Address Line 1', data.address_line_1);
    formData.append('Address Line 2', data.address_line_2);
    formData.append('County', data.county);
    formData.append('Postcode', data.postcode);
    formData.append('Email', data.email);
    formData.append('Phone Number', data.phoneNumber);
    formData.append('Interested In', '');

    {
      Object.keys(data).map(key => {
        if (
          key != 'fullName' &&
          key != 'address_line_1' &&
          key != 'address_line_2' &&
          key != 'county' &&
          key != 'postcode' &&
          key != 'email' &&
          key != 'phoneNumber' &&
          key != 'Email' &&
          key != 'Phone' &&
          key != 'Post'
        ) {
          if (data[key] === true) {
            formData.append(key, '');
          }
        }
      });
    }

    formData.append('Contact Methods', '');
    if (data.Email === true) {
      formData.append('By Email', '');
    }
    if (data.Phone === true) {
      formData.append('By Phone', '');
    }
    if (data.Post === true) {
      formData.append('By Post', '');
    }

    axios({
      method: 'POST',
      url: `${process.env.formspree_uri}`,
      data: formData,
    })
      .then(() => {
        handleServerResponse(true, 'Excellent, that is everything we need');
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error);
      });
  };

  return (
    <Container maxWidth="md">
      <Typography component="h4" variant="h4" align="center">
        {data.form_title[0].text}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        mt={[5, 10]}
        position="relative"
      >
        {serverState.submitting && (
          <Box
            className={classes.submitting}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <CircularProgress />
            <Box py={1} />
            <Typography component="p" variant="h6">
              Submitting
            </Typography>
          </Box>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Box pr={[0, 4, 4]} className={classes.border}>
                <Box display="flex" flexDirection="column" mb={4}>
                  <Typography
                    component="label"
                    variant="caption"
                    htmlFor="FullName"
                    className={classes.label}
                  >
                    Your Full Name
                  </Typography>
                  <input
                    type="text"
                    id="FullName"
                    name="fullName"
                    ref={register({ required: true })}
                    className={classes.input}
                  />
                  {errors.fullName && (
                    <Box pt={1} display="flex" alignItems="center">
                      <WarningIcon className={classes.errorIcon} />
                      <Typography component="p" variant="button" color="error">
                        Name is required
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" mb={4}>
                  <Typography
                    component="label"
                    variant="caption"
                    htmlFor="address_line_1"
                    className={classes.label}
                  >
                    Your Address
                  </Typography>
                  <Box mb={2}>
                    <input
                      type="text"
                      id="address"
                      name="address_line_1"
                      ref={register({ required: true })}
                      className={classes.input}
                    />
                  </Box>
                  <Typography
                    component="label"
                    variant="caption"
                    htmlFor="address_line_2"
                    className={classes.labelHidden}
                  >
                    Your Address
                  </Typography>
                  <input
                    type="text"
                    id="address"
                    name="address_line_2"
                    className={classes.input}
                  />
                  {errors.address_line_1 && (
                    <Box pt={1} display="flex" alignItems="center">
                      <WarningIcon className={classes.errorIcon} />
                      <Typography component="p" variant="button" color="error">
                        Address is required
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" mb={4}>
                  <Typography
                    component="label"
                    variant="caption"
                    htmlFor="county"
                    className={classes.label}
                  >
                    County
                  </Typography>
                  <select
                    id="county"
                    name="county"
                    ref={register({ required: true })}
                    className={classes.input}
                  >
                    {counties.map((county, item) => (
                      <option key={item} value={county}>
                        {county}
                      </option>
                    ))}
                  </select>
                  {errors.county && (
                    <Box pt={1} display="flex" alignItems="center">
                      <WarningIcon className={classes.errorIcon} />
                      <Typography component="p" variant="button" color="error">
                        County is required
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" mb={4}>
                  <Typography
                    component="label"
                    variant="caption"
                    htmlFor="postcode"
                    className={classes.label}
                  >
                    Postal Code
                  </Typography>
                  <input
                    type="text"
                    id="postcode"
                    name="postcode"
                    ref={register({ required: true })}
                    className={`${classes.input} ${classes.halfWidth}`}
                  />
                  {errors.postcode && (
                    <Box pt={1} display="flex" alignItems="center">
                      <WarningIcon className={classes.errorIcon} />
                      <Typography component="p" variant="button" color="error">
                        Postcode is required
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" mb={4}>
                  <Typography
                    component="label"
                    variant="caption"
                    htmlFor="email"
                    className={classes.label}
                  >
                    Email
                  </Typography>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    ref={register({
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'invalid email address',
                      },
                    })}
                    className={classes.input}
                  />
                  {errors.email && (
                    <Box pt={1} display="flex" alignItems="center">
                      <WarningIcon className={classes.errorIcon} />
                      <Typography component="p" variant="button" color="error">
                        {errors.email.message
                          ? errors.email.message
                          : 'Email address required'}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" mb={4}>
                  <Typography
                    component="label"
                    variant="caption"
                    htmlFor="phoneNumber"
                    className={classes.label}
                  >
                    Phone Number
                  </Typography>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    ref={register({
                      required: true,
                    })}
                    className={classes.input}
                  />
                  {errors.phoneNumber && (
                    <Box pt={1} display="flex" alignItems="center">
                      <WarningIcon className={classes.errorIcon} />
                      <Typography component="p" variant="button" color="error">
                        Phone number required
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box pl={[0, 0, 0, 4]}>
                <Box>
                  <Box>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.label}
                    >
                      PREFERRED METHOD OF CONTACT
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    py={2}
                  >
                    {ContactMethods.map((method, key) => (
                      <Box
                        key={key}
                        position="relative"
                        display="flex"
                        alignItems="center"
                      >
                        <Box mr={1}>
                          <input
                            type="checkbox"
                            id={method}
                            name={method}
                            ref={register}
                            className={`${classes.checkbox} checkbox`}
                          />
                          <Box className={`${classes.checkmark} checkmark`} />
                        </Box>
                        <Typography
                          component="label"
                          variant="caption"
                          htmlFor={method}
                          className={classes.label}
                        >
                          {method}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Box pt={4}>
                  <Box mb={2}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.label}
                    >
                      WHICH DEVELOPMENT(S) ARE YOU INTERESTED IN?
                    </Typography>
                  </Box>
                </Box>
                <Box my={5} className={classes.divider} />
                <Box>
                  <input
                    type="submit"
                    className={classes.btn}
                    value="REGISTER YOUR INTEREST"
                    disabled={serverState.submitting}
                  />
                </Box>
                <Box>
                  {serverState.status && (
                    <Box>
                      {!serverState.status.ok && (
                        <Box>
                          <Typography
                            component="p"
                            variant="h5"
                            align="center"
                            style={{ color: 'red' }}
                          >
                            Sorry, there has been an error with your submission.
                            Please try again or contact us at{' '}
                            {process.env.public_email}
                            with error message {serverState.status.msg}
                          </Typography>
                        </Box>
                      )}

                      {serverState.status.ok && (
                        <Box mb={4}>
                          <Typography
                            component="p"
                            variant="h6"
                            align="left"
                            gutterBottom
                          >
                            {serverState.status.msg}
                          </Typography>
                          <Typography
                            component="p"
                            variant="subtitle1"
                            align="left"
                          >
                            One of our team will be in contact as soon as
                            possible
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
}

ContactForm.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactForm);
