import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { TitleCaptionText } from 'components/slices';
import { withStyles } from '@material-ui/styles';
import { ButtonOutlineDark, ButtonOutlineDarkExternal } from 'components/ui';
import linkResolver from 'utils/linkResolver';

const styles = {
  image: {
    maxWidth: '100%',
  },
};

function TextLeftImageRight(props) {
  const { data, classes } = props;
  return (
    <Container maxWidth={false}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={6}>
          <Img
            className={classes.image}
            fluid={data.imageSharp.childImageSharp.fluid}
            alt={data.image.alt}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box px={[0, 2, 2, 16]} mt={{ xs: 10, md: 0 }}>
            <TitleCaptionText
              data={data}
              titlecomponent="h4"
              xs={12}
              md={12}
              lg={12}
            />
            <Box ml={{ xs: 0, md: 10 }}>
              {data.link && (
                <React.Fragment>
                  {data.link_title &&
                    data.link.__typename != 'PRISMIC__ExternalLink' && (
                      <ButtonOutlineDark
                        text={data.link_title[0].text}
                        link={`${
                          data.link ? linkResolver(data.link._meta) : '/'
                        }`}
                      />
                    )}
                  {data.link_title &&
                    data.link.__typename == 'PRISMIC__ExternalLink' && (
                      <ButtonOutlineDarkExternal
                        text={data.link_title[0].text}
                        link={`${data.link.url ? data.link.url : '/'}`}
                      />
                    )}
                </React.Fragment>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

TextLeftImageRight.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextLeftImageRight);
