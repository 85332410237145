import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Logo from 'images/LivingSpaceLogoWhite.svg';
import BackgroundImage from 'gatsby-background-image';

const styles = theme => ({
  uppercase: {
    textTransform: 'uppercase',
  },
  bgImageWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    minHeight: '400px',
    padding: '40px 0px',
    [theme.breakpoints.up('lg')]: {
      minHeight: '800px',
    },
  },
  body: {
    fontSize: '1rem',
    letterSpacing: '0.75px',
  },
  signature_image: {
    width: '100px',
  },
  logo: {
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      width: '200px',
    },
  },
});

function FounderQuote(props) {
  const { data, classes } = props;
  return (
    <Container maxWidth={false}>
      <BackgroundImage
        className={classes.bgImageWrapper}
        Tag="section"
        fluid={data.imageSharp.childImageSharp.fluid}
      >
        <Grid container>
          <Grid item xs={1} />
          <Box
            component={Grid}
            item
            xs={12}
            md={4}
            display="flex"
            alignItems="center"
            flexDirection="column"
            p={4}
          >
            <Box mb={2}>
              <Logo className={classes.logo} />
            </Box>
            <Typography
              variant="h3"
              component="p"
              color="secondary"
              align="center"
            >
              {data.title[0].text}
            </Typography>
            <Box py={4}>
              <Typography
                component="p"
                variant="subtitle2"
                color="secondary"
                align="center"
                className={classes.body}
              >
                {data.text[0].text}
              </Typography>
            </Box>
            <Box mb={2}>
              <Img
                fluid={data.signature_imageSharp.childImageSharp.fluid}
                alt={data.signature_image.alt}
                className={classes.signature_image}
              />
            </Box>
            <Typography component="p" variant="h6" color="secondary">
              {data.author[0].text}
            </Typography>
            <Typography component="p" variant="button" color="secondary">
              {data.job_title[0].text}
            </Typography>
          </Box>
        </Grid>
      </BackgroundImage>
    </Container>
  );
}

FounderQuote.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(FounderQuote);
