import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import BackgroundImage from 'gatsby-background-image';
import { withStyles } from '@material-ui/styles';

const styles = {
  wrapper: {
    height: '585px',
  },
  container: {
    height: '100% !important',
  },
  imagebox: {
    height: '100%',
    width: '100%',
  },
};

function FourImageGrid(props) {
  const { data, classes } = props;
  return (
    <Container maxWidth={false}>
      <Box className={classes.wrapper}>
        <div className={`${classes.container} spaced-grid-wrapper`}>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12}>
                  <BackgroundImage
                    Tag="div"
                    className={classes.imagebox}
                    fluid={data.tile1_imageSharp.childImageSharp.fluid}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BackgroundImage
                    Tag="div"
                    className={classes.imagebox}
                    fluid={data.tile2_imageSharp.childImageSharp.fluid}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BackgroundImage
                    Tag="div"
                    className={classes.imagebox}
                    fluid={data.tile3_imageSharp.childImageSharp.fluid}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12}>
                  <BackgroundImage
                    Tag="div"
                    className={classes.imagebox}
                    fluid={data.tile4_imageSharp.childImageSharp.fluid}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Container>
  );
}

FourImageGrid.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FourImageGrid);
