import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  wrapper: {
    border: `1px solid ${theme.palette.background.border}`,
    height: '100%',
  },
  role: {
    color: theme.palette.text.lblue,
    letterSpacing: '3px',
    textTransform: 'uppercase',
  },
});

const TeamMember = props => {
  const { data, classes } = props;
  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Box className={classes.wrapper} component={Grid} container>
        <Box component={Grid} item xs={12}>
          <Img
            fluid={data.imageSharp.childImageSharp.fluid}
            alt={data.image.alt}
          />
        </Box>
        <Box
          component={Grid}
          item
          xs={12}
          p={4}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Box>
            <Typography component="p" variant="h6" align="center">
              {data.name[0].text}
            </Typography>
            {data.credentials &&
              data.credentials[0] &&
              data.credentials[0].text && (
                <Typography component="p" variant="subtitle1" align="center">
                  {data.credentials[0].text}
                </Typography>
              )}
          </Box>
          <Box py={2}>
            <Typography component="p" variant="button" className={classes.role}>
              {data.job_role[0].text}
            </Typography>
          </Box>
          <Box>
            <Typography
              component="a"
              variant="button"
              className={classes.role}
              href={`mailto:${data.email[0].text}`}
            >
              Email {data.name[0].text}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

TeamMember.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

function TeamMembers(props) {
  const { data, fields, classes } = props;
  return (
    <Container maxWidth={false}>
      {data.title && data.title[0] && (
        <Box mb={4}>
          <Typography component="h3" variant="h4" align="center">
            {data.title[0].text}
          </Typography>
        </Box>
      )}
      <div className="spaced-grid-wrapper">
        <Grid container spacing={5}>
          {fields.map((item, key) => {
            return <TeamMember key={key} data={item} classes={classes} />;
          })}
        </Grid>
      </div>
    </Container>
  );
}

TeamMembers.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeamMembers);
