import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ButtonBlockDark } from 'components/ui';
import { withStyles } from '@material-ui/styles';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const styles = theme => ({
  contactItem: {
    textTransform: 'none',
    letterSpacing: '0.75px',
    listStyle: 'none',
    '&:last-of-type': {
      paddingLeft: '2rem',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '0',
      },
    },
  },
  underline: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
});

function RegisterInterest(props) {
  const { classes, telephone, email } = props;
  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Typography component="p" variant="h2">
          REGISTER INTEREST
        </Typography>
        <Box
          component="ul"
          p={0}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Typography
            component="li"
            variant="subtitle1"
            className={classes.contactItem}
          >
            T –{' '}
            <Box component="span" className={classes.underline}>
              {telephone}
            </Box>
          </Typography>
          <Typography
            component="li"
            variant="subtitle1"
            className={classes.contactItem}
          >
            E –{' '}
            <Box component="span" className={classes.underline}>
              {email}
            </Box>
          </Typography>
        </Box>
      </Grid>
      <Box
        component={Grid}
        item
        xs={12}
        md={4}
        display="flex"
        justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
        alignItems="center"
      >
        <ButtonBlockDark
          text="MAKE AN ENQUIRY"
          link={`/${process.env.contact_path}`}
          icon={<ArrowForwardIosIcon />}
        />
      </Box>
    </Grid>
  );
}

RegisterInterest.propTypes = {
  classes: PropTypes.object.isRequired,
  telephone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default withStyles(styles)(RegisterInterest);
