import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';
import { withStyles } from '@material-ui/styles';
import theme from '../../styles/theme';

const styles = theme => ({
  body: {
    textTransform: 'none',
    letterSpacing: '0.75px',
  },
  light: {
    color: theme.palette.text.lblue,
  },
});

function Office(props) {
  const { data, classes } = props;
  return (
    <Container maxWidth="lg">
      <Grid container>
        <Box component={Grid} item xs={12} lg={4} mb={[4, 4, 4, 0]}>
          <Typography component="h3" variant="h4">
            {data.office_name[0].text}
          </Typography>
        </Box>
        <Grid item xs={6} md={4} lg>
          <Typography component="p" variant="subtitle1" gutterBottom>
            Address
          </Typography>
          <Typography
            component="div"
            variant="subtitle1"
            className={classes.body}
          >
            <RichText render={data.address} />
          </Typography>
        </Grid>
        <Grid item xs={6} md={4} lg>
          <Typography component="p" variant="subtitle1" gutterBottom>
            Phone
          </Typography>
          <Typography
            component="p"
            variant="subtitle1"
            className={classes.body}
            gutterBottom
          >
            {data.telephone[0].text}
          </Typography>
          {data.office_hours && data.office_hours[0] && (
            <Typography
              component="p"
              variant="button"
              className={`${classes.body} ${classes.light}`}
            >
              {data.office_hours[0].text}
            </Typography>
          )}
          {data.additional_opening_details && (
            <Typography
              component="p"
              variant="button"
              className={`${classes.body} ${classes.light}`}
            >
              {data.additional_opening_details[0].text}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={4} lg>
          <Typography component="p" variant="subtitle1" gutterBottom>
            Email
          </Typography>
          <Typography
            component="p"
            variant="subtitle1"
            className={classes.body}
            gutterBottom
          >
            <a
              href={`mailto:${data.email_address[0].text}`}
              rel="noopener nofollow"
            >
              {data.email_address[0].text}
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

Office.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Office);
