import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

function TitleText(props) {
  const { data, xs, md, lg } = props;
  const xsVal = xs ? xs : 12;
  const mdVal = md ? md : 6;
  const lgVal = lg ? lg : 5;
  return (
    <Box component={Grid} container justifyContent="center">
      <Grid item xs={xsVal} md={mdVal} lg={lgVal}>
        <Box mb={4}>
          <Typography component="h3" variant="h3" align="center">
            {data.title[0].text}
          </Typography>
        </Box>
        <Typography component="p" variant="body1" align="center">
          {data.text[0].text}
        </Typography>
      </Grid>
    </Box>
  );
}

TitleText.propTypes = {
  data: PropTypes.object.isRequired,
  xs: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

export default TitleText;
